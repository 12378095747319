const { DateTime } = require('luxon');

(function () {
    'use strict';

    bindhq.nsIn('forms.application', {
        /**
         * @type {String}
         */
        termAutofillTpl:
            '<div class="row">' +
            '<div class="col-12 span12 term-autofill">' +
            'Set to ' +
            '<a href="#">3</a> / ' +
            '<a href="#">6</a> / ' +
            '<a href="#">12</a> / ' +
            '<a href="#">18</a> / ' +
            '<a href="#">24</a> ' +
            'months' +
            '</div>' +
            '</div>',

        sidebarItemTpl:
            '<li class="<%= active %>">' +
            '   <a href="#product-<%= index %>" id="tab-product-<%= index %>" data-toggle="<%= toggle %>">' +
            '      <%= title %>' +
            '   </a>' +
            '</li>',

        tabItemTpl:
            '<div class="tab-pane content-product product-forms" id="product-<%= index %>"><%= content %></div>',

        /**
         * @param {jQuery} agency
         */
        updateAgencyLink: function (agency) {
            const agencyId = agency.select2('val');

            $('.agency-link').remove();

            if (agencyId !== '') {
                $('<a></a>')
                    .html('View Agency &raquo;')
                    .attr('href', '/secure/admin/agencies/' + agencyId)
                    .attr('class', 'agency-link')
                    .attr('target', '_blank')
                    .insertAfter('.select2-container.agency');
            }
        },

        /**
         * @param {jQuery} agency
         * @param {jQuery} agent
         */
        updateAgentUrl: function (agency, agent) {
            const agencyId = agency.select2('val');

            agent.data('ajaxurl', function () {
                return agent
                    .get(1)
                    .dataset.ajaxurlPattern.replace(/{agencyId}/, agencyId);
            });
        },

        updateCheckbox: function (e) {
            const target = $(e.target);
            if (!target.is('label') && !target.is('input')) {
                const checkboxArea = $(e.target).closest('.checkbox-area');
                const input = $('input', checkboxArea);
                if (!input.attr('disabled')) {
                    input.prop('checked', function (i, val) {
                        return !val;
                    });
                    input.trigger('change');
                }
            }
        },

        /**
         * @param {jQuery} agency
         * @param {jQuery} agent
         */
        agencyChanged: function (agency, agent) {
            this.updateAgencyLink(agency);
            this.updateAgentUrl(agency, agent);
        },

        /**
         * @param {jQuery} term
         * @param {jQuery.Event} evt
         */
        onTermHelperClick: function (term, evt) {
            const anchor = $(evt.currentTarget);
            const months = parseInt(anchor.text(), 10);
            const effective = term.datepicker('getDate');
            const expiry = DateTime.fromISO(effective)
                .plus({ months })
                .toJSDate();

            term.datepicker('setDate', expiry);
        },

        /**
         * @param {jQuery} container
         */
        initTermHelpers: function (container) {
            const html = bindhq.util.template(this.termAutofillTpl, {});
            const term = $('.term', container);
            const onClick = _.partial(this.onTermHelperClick, term);

            $(html)
                .insertAfter(term)
                .on('click', 'a', bindhq.util.noDefault(onClick));
        },

        cancelApplication: function (container) {
            const appId = container.data('app-id');
            if (appId) {
                bindhq.util.post('/secure/applications/' + appId + '/cancel');
            } else {
                window.location.href = '/secure/applications/new';
            }
        },

        loadInsuredInfo: function (container) {
            const extraInfoArea = $('.info-sidebar .extra-info', container);
            const insured = container.find('.insured').select2('val');

            if (insured) {
                const url = '/secure/admin/insureds/' + insured + '/info';
                $('.insured-extra-info', extraInfoArea).remove();
                this.loadExtraInfo(url, extraInfoArea);
            }
        },

        loadAgentInfo: function (container) {
            const extraInfoArea = $('.info-sidebar .extra-info', container);
            const agent = container.find('.agent').select2('val');
            const agency = container.find('.agency').select2('val');

            if (agent) {
                const url =
                    '/secure/admin/agencies/' +
                    agency +
                    '/agents/' +
                    agent +
                    '/info';
                $('.agent-extra-info', extraInfoArea).remove();
                this.loadExtraInfo(url, extraInfoArea);
            }
        },

        loadExtraInfo: function (url, extraInfoArea) {
            const success = function (data) {
                extraInfoArea.append(data);
            };
            $.get(url, {}, success);
        },

        displayLocalProducts: function (container) {
            const displayArea = $('.tab-content', container);
            const sidebarArea = $('.app-form-sidebar ul', container);
            const hazards = $('.hazards', container);
            const products = $('[data-prototype]:checked', hazards);

            _.each(
                products,
                function (product, index) {
                    const html = $(product)
                        .data('prototype')
                        .replace(
                            new RegExp('application_hazards___name__', 'g'),
                            'application_hazards_' + index,
                        )
                        .replace(
                            new RegExp(
                                'application\\[hazards\\]\\[__name__',
                                'g',
                            ),
                            'application[hazards][' + index,
                        );
                    const contentHtml = bindhq.util.template(this.tabItemTpl, {
                        index: index,
                        content: html,
                    });
                    const sidebarHtml = bindhq.util.template(
                        this.sidebarItemTpl,
                        {
                            index: index,
                            title: $(product).attr('id'),
                            active: false,
                            toggle: 'pill',
                        },
                    );

                    const element = $(contentHtml).appendTo(displayArea);

                    $(sidebarHtml).appendTo(sidebarArea);

                    bindhq.initContainer(element);
                    bindhq.forms.init.apply(element);
                }.bind(this),
            );

            $('#tab-product-0', container).tab('show');
        },

        isOkToBack: function (container) {
            const index = $('.app-form-sidebar li.active', container).index();
            return index !== 0 && index !== 3;
        },

        isLastStep: function (container) {
            return $('.app-form-sidebar li', container)
                .last()
                .hasClass('active');
        },

        updateNavActions: function (container) {
            if (this.isOkToBack(container)) {
                $('.bottom-navigation-area', container).addClass(
                    'backable-step',
                );
            } else {
                $('.bottom-navigation-area', container).removeClass(
                    'backable-step',
                );
            }

            if (this.isLastStep(container)) {
                $('.bottom-navigation-area', container).addClass('last-step');
            } else {
                $('.bottom-navigation-area', container).removeClass(
                    'last-step',
                );
            }
        },

        onProductContinue: function (container, e) {
            const currentIndex = $('.app-form-sidebar li.active', container);

            const next = currentIndex.next();
            const tabIndex = $('a', next);

            if (tabIndex.get(0)) {
                tabIndex.tab('show');
                this.updateNavActions(container);
            }
        },

        onProductsSelected: function (container, e) {
            const productsType = $('.product-hazards:visible', container);
            $('.error', productsType).remove();

            const type = $(
                '.app-purpose-selector input:checked',
                container,
            ).val();

            if ($('input:checked', productsType).length) {
                if (type === 'manual') {
                    container.addClass('is-local');
                    this.displayLocalProducts(container, e);
                }

                this.updateNavActions(container);
            } else {
                productsType.append(
                    '<label class="error">Please select one option</label>',
                );
            }
        },

        onBasicContinue: function (container) {
            if (container.valid()) {
                $('#tab-select', container).tab('show');
            }
            this.updateNavActions(container);
        },

        onPurposeChanged: function (container, e) {
            const products = $('.product-hazards .checkbox-tick', container);
            const type = $(
                '.app-purpose-selector input:checked',
                container,
            ).val();

            products.prop('checked', false);
            products.trigger('change');
            products.attr('disabled', true);

            if (type === 'manual') {
                $('.' + type + '-hazard', container).attr('disabled', false);
            }
        },

        onProductBack: function (container, e) {
            const currentIndex = $('.app-form-sidebar li.active', container);

            const prev = currentIndex.prev();
            const tabIndex = $('a', prev);

            if (tabIndex.get(0)) {
                tabIndex.tab('show');
                this.updateNavActions(container);
            }
        },

        onBasicBack: function (container) {
            $('#tab-basic', container).tab('show');
            this.updateNavActions(container);
        },

        onCancelApplication: function (container) {
            swal(
                {
                    title: 'Warning!',
                    text: 'Cancelling the process will result in loss of some information. Do you wish to continue?',
                    type: 'warning',
                    confirmButtonText: 'Yes',
                    cancelButtonText: 'No',
                    showCancelButton: true,
                },
                function (value) {
                    if (value) {
                        this.cancelApplication(container);
                    }
                }.bind(this),
            );
        },

        onBack: function (container) {
            const activeTab = $('.tab-pane.active', container);

            if (activeTab.hasClass('content-select')) {
                this.onBasicBack(container);
            } else if (activeTab.hasClass('content-product')) {
                this.onProductBack(container);
            }
        },

        onContinue: function (container) {
            const activeTab = $('.tab-pane.active', container);

            if (activeTab.hasClass('content-basic')) {
                this.onBasicContinue(container);
            } else if (activeTab.hasClass('content-select')) {
                this.onProductsSelected(container);
            } else if (activeTab.hasClass('content-product')) {
                this.onProductContinue(container);
            }
        },

        initNavActions: function (container) {
            const cancelAction = $('.cancel-action', container);
            const backAction = $('.back-action', container);
            const continueAction = $('.continue-action', container);

            const onContinue = _.partial(this.onContinue, container);
            const onBack = _.partial(this.onBack, container);
            const onCancel = _.partial(this.onCancelApplication, container);

            continueAction.on('click', onContinue);
            backAction.on('click', onBack);
            cancelAction.on('click', onCancel);
        },

        initEdit: function (container) {
            const applicationOrn = $('body').data('application-orn');
            const displayArea = $('.product-forms', container).get(0);

            $('#tab-product', container).tab('show');
            this.updateNavActions(container);
        },

        /**
         * @param {jQuery} container
         */
        initNew: function (container) {
            const agency = $('.agency', container);
            const agent = $('.agent', container);
            const insured = $('.insured', container);
            const selectPurposeAction = $(
                '.app-purpose-selector input',
                container,
            );

            const updateAgency = this.agencyChanged;
            const onAgentChange = _.partial(this.loadAgentInfo, container);
            const onInsuredChange = _.partial(this.loadInsuredInfo, container);
            const onPurposeChanged = _.partial(
                this.onPurposeChanged,
                container,
            );

            bindhq.forms.ajaxselect.dependsOn(agency, agent, updateAgency);

            updateAgency(agency, agent);

            $('.documents', container).each(bindhq.applications.documents.init);

            $('.checkbox-area', container).click(this.updateCheckbox);

            agent.on('change', onAgentChange);
            insured.on('change', onInsuredChange);
            selectPurposeAction.on('change', onPurposeChanged);

            this.initTermHelpers(container);
        },

        initContainer: function (container) {
            const applicationId = container.data('application');

            if (applicationId) {
                this.initEdit(container);
            } else {
                this.initNew(container);
            }

            this.initNavActions(container);
        },
    });
})();
